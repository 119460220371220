import logo from "../../assets/images/primary-logo-stacked_duo-color_lightgreen+black.svg";

const WebsiteOfflineView = () => {
  return (
    <div className="offline-view">
      <div className="offline-view__text">
        <div className="offline-view__text--logo">
          <img src={logo} alt="Studio Maandag" />
        </div>

        <div className="offline-view__text--contact">
          <h1>
            Studio Maandag is
            <br /> <span className="emph">on a break</span>
          </h1>
          <p>
            Hi, ik ben (tijdelijk) gestopt met Studio Maandag. Voor vragen of
            overige zaken kun je me bereiken op{" "}
            <a href="mailto:info@studiomaandag.nl">info@studiomaandag.nl</a>.
          </p>
        </div>
      </div>
      <div className="offline-view__image" />
    </div>
  );
};

export default WebsiteOfflineView;
